import styled from "styled-components/macro"
import { Link } from "~components"
import { Box, Heading, Button, Paragraph } from "~styled"

const Card = styled(Box)`
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 25px;

  &:hover {
    .blog-bgoverlay {
      bottom: 0;
    }
  }
`
Card.Title = styled(Link)`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 25px;
  margin-bottom: 19px;
  color: #fff;
  display: block;
  transition: 0.4s;
  &:hover {
    color: #75aa16;
  }
`
Card.Badge = styled(Button)`
  font-size: 11px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1;
  min-width: 78px;
  height: 26px;
  border-radius: 15px;
  margin-right: 12px;
  color: #fff;
  padding: 8px;
  cursor: unset !important;
  &:hover {
    color: #fff;
  }
`
Card.Date = styled(Paragraph)`
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 22px;
  color: #fff;
  transition: 0.4s;
`
Card.Box = styled(Box)``
Card.Image = styled(Box)`
  .gatsby-image-wrapper-constrained {
    display: block;
  }
  img {
    width: 100%;
    height: 280px !important;
  }
`
Card.Top = styled(Box).attrs({ className: "blog-top" })`
  display: flex;
  align-items: center;
`
Card.Bottom = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

Card.OverlayBlock = styled(Box).attrs({ className: "blog-bgoverlay" })`
  width: 100%;
  border-radius: 15px;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: 0px;
  padding-top: 23px;
  padding-left: 25px;
  padding-right: 23px;
  transition: 0.4s;
  background-image: linear-gradient(
    360deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0.67) 45%,
    rgba(0, 0, 0, 0) 100%
  );
  @media (min-width: 768px) {
    padding-right: 0px;
  }
  @media (min-width: 992px) {
    padding-right: 23px;
  }
`

export default Card
