import React from "react"
import { graphql } from "gatsby"
import { BlogRegular } from "~sections/Blog"
import FooterSection from "~sections/utility/Footer"
import { PageWrapper } from "~components/Core"

export default function blog({ data }) {
  return (
    <PageWrapper innerPage={true}>
      <BlogRegular data={data} />
      <FooterSection />
    </PageWrapper>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          category
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
      }
    }
  }
`
