import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Blog from "./style"
import BlogCard from "./Comonent/Card"

export default function BlogRegular({ data }) {
  const { posts } = data.blog

  return (
    <Blog backgroundColor="#f9fafc">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-8 text-center">
            <Blog.Box mb="63px">
              <Blog.Subtitle as="h6" fontColor="#75aa16">
                Blog
              </Blog.Subtitle>
              <Blog.Title as="h2">Latest Posts</Blog.Title>
            </Blog.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {posts.map(post => {
            return (
              <Col
                xs="12"
                className={"col-lg-4 col-md-6 col-xs-9"}
                key={post.id}
              >
                <BlogCard
                  slug={post.fields.slug}
                  image={post.frontmatter.hero_image}
                  image_alt={post.frontmatter.hero_image_alt}
                  badge={post.frontmatter.category}
                  date={post.frontmatter.date}
                  title={post.frontmatter.title}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    </Blog>
  )
}
