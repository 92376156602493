import React from "react"
import { SuperTag } from "~components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Card from "./style"
export default function BlogCard({
  text,
  title,
  Like,
  date,
  user,
  commentCount,
  badge,
  image,
  image_alt,
  slug,
  ...rest
}) {
  const path = `/blog${slug}`
  const hero_image = getImage(image)
  return (
    <Card>
      <Card.Image>
        <GatsbyImage image={hero_image} alt={image_alt} />
      </Card.Image>
      <Card.OverlayBlock>
        <Card.Top mb="20px">
          <Card.Badge backgroundColor="#75AA16">{badge}</Card.Badge>
          <Card.Date>{date}</Card.Date>
        </Card.Top>
        <Card.Title to={path}>
          <SuperTag value={title} />
        </Card.Title>
      </Card.OverlayBlock>
    </Card>
  )
}
